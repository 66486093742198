<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12">
                    <b-tabs vertical lazy content-class="mt-3">
                        <b-tab v-for="(group,index) in groups()"
                               :key="group"
                               :active="activeTab==group"
                               :title="group"
                        >
                            <settings-translation-group :group="group"></settings-translation-group>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import SettingsTranslationGroup from "@/views/settings/translation_group";

export default {
    name: 'SettingsTranslations',
    components: {SettingsTranslationGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            activeTab: null,
            translations: [],
        }
    },
    methods: {
        ...mapGetters('I18NStore', ['messages', 'groups']),
        shown() {
            const groups = this.$store.dispatch('I18NStore/fetchGroups')
            Promise.all([groups])
                .then(() => {
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.shown()
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>