<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col class="text-right pb-2">
                    <b-button variant="info"
                              class="ml-2"
                              @click="$root.$children[0].openModal('upload-file-modal',
                                              {
                                                  store_dispatch_name:'I18NStore/importTranslations',
                                                  data:group,
                                              }, shown, {width: '800px'});"
                    >
                        <font-awesome-icon icon="upload"/>
                        {{ $t('common.button.import') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.translations.label.key') }}</b-th>
                            <b-th v-for="language in languages()"
                                  :key="language.id"
                            >
                                {{ language.name }}
                            </b-th>
                            <b-th>{{ $t('settings.translations.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="(value,key) in translations"
                              :key="key"
                              @dblclick="()=>{$set(translations[key],'editing',true)}"
                        >
                            <b-th>{{ key }}</b-th>
                            <b-td v-for="language in languages()"
                                  :key="language.id"
                            >
                                <div v-if="!translations[key].editing">
                                    {{ value[language.code] || '' }}
                                </div>
                                <b-form-input v-else
                                              v-model="value[language.code]"
                                              autocomplete="off"
                                              @keyup.enter="()=>{save(key, translations[key])}"
                                ></b-form-input>
                            </b-td>
                            <b-td>
                                <b-button size="sm"
                                          variant="primary"
                                          v-if="!translations[key].editing"
                                          @click="()=>{$set(translations[key],'editing',true)}"
                                          :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>
                                <b-button size="sm"
                                          variant="warning"
                                          v-else
                                          @click="()=>{save(key, translations[key])}"
                                >
                                    <font-awesome-icon icon="save"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsTranslationGroup',
    mixins: [commonSave],
    props: {
        'group': {
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            activeTab: null,
            translations: [],
        }
    },
    methods: {
        ...mapGetters('I18NStore', ['groups', 'languages']),
        save(key, translation) {
            delete translation.editing

            this.$store.dispatch('I18NStore/saveTranslation', {
                group: this.group,
                key: key,
                translations: translation,
            })
                .then((response) => {
                    this.$set(translation, 'editing', false)
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                    this.$root.showMessage(
                        this.$t('common.form.title.error'),
                        this.$t('common.toasts.validation_error'),
                        'danger'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        shown() {
            const messages = this.$store.dispatch('I18NStore/fetchGroupMessages', this.group)

            Promise.all([messages])
                .then((response) => {
                    this.translations = response[0]
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.shown()
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>